<script setup lang="ts">
    import { useLanguageStore } from '~~/stores/language';
    import useAssets from './composables/useAssets';

    const languageStore = useLanguageStore();
    const assets = useAssets();

    const lang = computed(() => {
        return languageStore.language as keyof Lang;
    });

    const props = defineProps({
        error: {
            type: Object,
            default() {
                return {}
            }
        }
    })
</script>

<template>
    <div class="h-screen w-full flex place-items-center align-center justify-center bg-gradient-to-t from-indigo-200 to-blue-50 text-clive-black">
        <div class="grid place-items-center">
            <div class="flex gap-2 mb-4 place-items-center">
                <img :src="assets.clive_logo" class="h-12">
                <img :src="assets.consumerLive" class="h-[26px]">
            </div>
            <span class="text-9xl text-clive-logo-blue font-extrabold bouncy-bouncy">{{ props.error.statusCode }}</span>
            <h1 class="text-4xl my-4 font-bold">{{ languageStore.page_404.h1[lang] }}</h1>
            <p class="mt-2 mb-6 w-4/5 text-center">{{ languageStore.page_404.p[lang] }}</p>
            <NuxtLink to="/">
                <AtomButton :content="languageStore.page_404.a[lang]"/>
            </NuxtLink>
        </div>
    </div>
</template>

<style>
.bouncy-bouncy {
    animation: bouncy-bouncy 1s infinite;
}

@keyframes bouncy-bouncy {
    0%, 100% {
        transform: translateY(-5%) scale(1, 1);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateY(0) scale(1, 0.98);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}
</style>